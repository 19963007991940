export const MenuItems = [
    {
        title: 'home',
        url: 'home',
        cName : 'nav-link',
    },
    {
        title: 'about',
        url: 'about',
        cName : 'nav-link',
    },
    {
        title: 'services',
        url: 'services',
        cName : 'nav-link',
    },
    {
        title: 'contact',
        url: 'contact',
        cName : 'nav-link',
    }
]